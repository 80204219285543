module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://ardorem.tech"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-segment/gatsby-browser.js'),
      options: {"plugins":[],"writeKey":"35oTlU4UqlhIN8VGYmBxAzyDdfzhcscw"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
